<template>
  <LayoutCard style="height: 100%">
    <div class="composite-card-performance">
      <Trash class="composite-card-chart-delete" @click="remove" />
      <div class="composite-card-performance-header">
        <span class="uptitle">{{ getChartName() }}</span>
        <div class="composite-card-chart-date">
          {{ new Date(chart.range_base[0]).toLocaleDateString('fr') }}
        </div>
      </div>
      <div class="composite-card-chart-selected">
        <p>
          <span>{{ chart.devices.length }}</span> Équipement(s)
        </p>
        <p v-if="chart.variables.length > 0">
          <span>{{ chart.variables.length }}</span> Variable(s)
        </p>
        <p v-if="chart.sensors.length > 0">
          <span>{{ chart.sensors.length }}</span> Capteur(s)
        </p>
      </div>
      <div class="composite-card-chart-deviceinfos" v-if="chart.devices.length > 0">
        <p v-for="device in chart.devices">
          {{ store_hypervision.map.dev.get(device)?.name }}
        </p>
      </div>
      <CommonButton style="margin-top: auto" class="btn btn-primary" @click="load">
        Charger le graphique
        <ArrowRight :size="18" />
      </CommonButton>
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import CommonButton from '../common/CommonButton.vue'
import { ArrowRight, Trash } from 'lucide-vue-next'
import type { components } from '@/types/gateway_schema'
import { useChartStore } from '@/stores/store_chart'
import { useRouter } from 'vue-router'
import { useGatewayStore } from '@/stores/store_gateway'
import { useHypervisionStore } from '@/stores/store_hypervision'

const store = useChartStore()
const store_hypervision = useHypervisionStore()
const gateway = useGatewayStore()
const router = useRouter()

const props = defineProps<{
  chart: components['schemas']['ChartDTO']
}>()

function load() {
  store.load(props.chart).then(() => {
    router.push('/dashboard/chart_builder')
  })
}

function remove() {
  if (confirm(`Souhaitez vous supprimer le graphique ?`)) {
    if (props.chart.id) {
      gateway.deleteChart(props.chart.id)
    }
  }
}

function getChartName() {
  if (props.chart.name) {
    return props.chart.name
  }

  return `Graphique n°${props.chart.id?.toString().padStart(5, '0')}`
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.composite-card-chart-deviceinfos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.composite-card-chart-date {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 5px;
  width: fit-content;
  background-color: black;
  color: white;
}

.composite-card-chart-deviceinfos > p {
  font-style: italic;
  font-size: 12px;
  color: var(--vc-gray4);
}

.composite-card-chart-delete {
  cursor: pointer;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: -15px;
  border-radius: 50%;
  right: -5px;
  padding: 5px;
  background-color: var(--vc-gray2);
  color: var(--vc-orange);
}
.composite-card-chart-delete:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: black;
}

.composite-card-chart-selected {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  p {
    background-color: var(--vc-orange);
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.composite-card-performance {
  @include vlayout(20px);
  align-items: center;
  height: 100%;
  position: relative;

  .composite-card-performance {
    &-header {
      background-color: var(--vc-gray2);
      padding: 20px;
      max-width: fit-content;
      text-transform: uppercase;
      text-align: center;
    }

    &-body {
      text-align: center;

      .economy {
        margin-top: 10px;
        color: var(--vc-green);
      }
    }
  }
}
</style>
