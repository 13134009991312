<template>
  <div class="chart-device">
    <div class="chart-device-chart">
      <div class="chart-device-sidebar">
        <ChartDeviceDatePicker
          style="margin-bottom: var(--spacer)"
          v-model="store_chart.dates"
          v-model:compare="store_chart.compare"
        />
        <ChartDeviceDataPicker
          v-if="store_chart.getDevice"
          :dates="store_chart.dates"
          :device="store_chart.getDevice"
        />
      </div>
      <div class="chart-device-chart-inner" v-if="store_chart.isVisible">
        <apexchart
          title="chart_base"
          :series="store_chart.getSeries('base')"
          :options="{
            chart: { id: 'base', height: 400 },
            yaxis: store_chart.getAxis('base'),
            xaxis: {
              type: 'datetime',
              labels: {
                format: 'dd/MM'
              }
            },
            title: { text: store_chart.getDevice?.name },
            tooltip: {
              x: {
                show: true,
                format: 'dd/MM HH:mm',
                formatter: undefined
              }
            }
          }"
        />
        <apexchart
          v-if="store_chart.dates.comp"
          title="chart_comp"
          :series="store_chart.getSeries('comp')"
          :options="{
            xaxis: {
              type: 'datetime',
              labels: {
                format: 'dd/MM'
              }
            },
            chart: { id: 'comp', height: 400 },
            yaxis: store_chart.getAxis('comp')
          }"
        />
      </div>
      <LayoutCard
        background="var(--vc-secondary)"
        style="color: white; flex-grow: 1; display: flex; gap: var(--spacer)"
        v-else
      >
        <Info />
        Selectionnez une valeur pour afficher un graphique
      </LayoutCard>
    </div>
    <CompositeChartFooter @on-save="store_chart.save" />
    <CompositeDeviceTotal v-if="store_chart.getDevice" :series="store_chart.getDevice.series" />
  </div>
</template>

<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css'
import 'chartjs-adapter-moment'
import CompositeChartFooter from '../CompositeChartFooter.vue'
import ChartDeviceDatePicker from './ChartDeviceDatePicker.vue'
import ChartDeviceDataPicker from './ChartDeviceDataPicker.vue'
import CompositeDeviceTotal from '../CompositeDeviceTotal.vue'
import { useChartStore } from '@/stores/store_chart'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted } from 'vue'
import LayoutCard from '@/components/layout/LayoutCard.vue'
import { Info } from 'lucide-vue-next'

defineProps<{
  id?: number
  buildable?: boolean
}>()

const store_chart = useChartStore()
const store = useHypervisionStore()

onMounted(() => {
  if (store_chart.dates.base === undefined) {
    store_chart.dates.base = [new Date(store.getMonth.first), new Date(store.getMonth.last)]
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/animations.scss';

.chart-device-chart {
  display: flex;
  gap: var(--spacer);
  margin-bottom: var(--spacer);
}

.chart-device-chart-inner {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}

.chart-device-sidebar {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.tmp {
  display: flex;
  gap: 10px;
}

.tmp a {
  padding: 5px;
  background-color: lightgray;
  border-radius: 5px;
}
</style>
