<template>
  <LayoutCard class="chart-device-datapicker">
    <p style="font-weight: 600; margin-bottom: var(--spacer)">{{ device.name }}</p>
    <p v-if="store_chart.getOptionsVars.length > 0" class="hide-on-print">Variables :</p>
    <a
      v-for="variable in store_chart.getOptionsVars"
      :title="variable.name"
      :class="{ selected: store_chart.getDevice?.selected.find((e) => e === variable.variableId) }"
      class="hide-on-print"
      @click="select_value(variable.variableId!)"
    >
      {{ variable.name }}
      ({{ getUnitLabel(store.map.uni.get(variable.unitId)!) }})
    </a>
    <p v-if="store_chart.getOptionsSens.length > 0" class="hide-on-print">Capteurs :</p>
    <a
      v-for="sensor in store_chart.getOptionsSens"
      :title="sensor.name"
      class="hide-on-print"
      :class="{ selected: store_chart.getDevice?.selected.find((e) => e === sensor.sensorId) }"
      @click="select_value(sensor.sensorId!)"
    >
      {{ sensor.name }}
      ({{ getUnitLabel(store.map.uni.get(sensor.unitId)!) }})
    </a>
    <CommonButton
      style="margin-left: auto; background-color: black; color: white"
      @click="store_chart.devices.forEach((d) => (d.selected = []))"
    >
      Déselectionner tout
    </CommonButton>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import type { components } from '@/types/hypervision_schema'
import type { ChartDeviceDatePickerData } from './ChartDeviceDatePicker.vue'
import { watch } from 'vue'
import { useChartStore } from '@/stores/store_chart'
import CommonButton from '@/components/common/CommonButton.vue'

const props = defineProps<{
  dates: ChartDeviceDatePickerData
  device: components['schemas']['Device']
}>()

function select_value(id: string) {
  store_chart.select(id)
  store_chart.fetch()
}

function getUnitLabel(unit: components['schemas']['Unit']) {
  if (!unit) return
  switch (unit.code) {
    case 'M2':
      return 'm²'
    case 'MC':
      return 'm³'
    case 'm3J':
      return 'm³/j'
    case 'm3A':
      return 'm³/an'
    default:
      return unit.symbol
  }
}

const store = useHypervisionStore()
const store_chart = useChartStore()
watch(props.dates, () => {
  store_chart.fetch()
})
</script>

<style scoped>
.chart-device-datapicker > a {
  transition: cubic-bezier(0.19, 1, 0.22, 1) 300ms;
  text-wrap: nowrap;
  display: block;
  padding: 10px;
  border-radius: var(--br-xsmall);
  background-color: var(--vc-gray3);
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--vc-gray4);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-device-datapicker > a:not(:last-child) {
  margin-bottom: 10px;
}

.selected {
  background-color: var(--vc-primary) !important;
  color: white !important;
}

a > span {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
</style>
