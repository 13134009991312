<template>
  <LayoutCard class="composite-card-data">
    <div class="composite-card-data-header">
      <div class="composite-card-data-header-icon">
        <img v-if="select === 'solar'" :src="IconSolarWhite" />
        <img v-else-if="select === 'aquasmart' || select === 'kipo'" :src="IconWaterManagement" />
        <Euro v-else />
      </div>
      <h3>Mes économies</h3>
    </div>
    <VueDatePicker
      :clearable="true"
      format="dd/MM/y"
      :action-row="{ showPreview: false }"
      :enable-time-picker="false"
      range
      v-model="range"
      cancel-text="Annuler"
      select-text="Valider"
      style="margin-bottom: 10px"
      @update:model-value="fetch_range"
    />
    <InputSelect
      id="category-select"
      v-model="select"
      @update:model-value="fetch_range"
      :options="[
        { label: 'Production solaire - Soprasolar', value: 'solar' },
        { label: 'Arrosage intelligent - Aquasmart', value: 'aquasmart' },
        { label: 'Récupération de l\'eau de pluie - Kipopluie', value: 'kipo' }
      ]"
      color="black"
      rounded
    />
    <p v-if="fetching">Chargement des données...</p>
    <template v-else>
      <CommonInlineData
        v-for="result in calculation_results.filter((e) => e.value !== 0)"
        :key="result.code"
        :label="result.title"
        :data="`${formatNumberWithSpaces(Math.round(result.value))} ${result.metric}`"
      />
    </template>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import InputSelect from '../common/input/InputSelect.vue'
import CommonInlineData from '../common/CommonInlineData.vue'
import { Euro } from 'lucide-vue-next'
import { onMounted, ref } from 'vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import IconWaterManagement from '@/assets/images/icons/icon-water-management-white.png'
import IconSolarWhite from '@/assets/images/icons/icon-solar-white.png'

import {
  get_device_values,
  type DeviceValue,
  calculate_total_month
} from '@/service/service_calculations'
import VueDatePicker from '@vuepic/vue-datepicker'
import CommonLoader from '../common/CommonLoader.vue'

const store = useHypervisionStore()
const calculation_results = ref<DeviceValue[]>([])
const select = ref<string>()
const range = ref<Date[]>([])
const fetching = ref<boolean>()

onMounted(() => {
  fetch_range()
})

function formatNumberWithSpaces(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

async function fetch_range() {
  if (range.value && range.value.length !== 0) {
    let filter: string | undefined = undefined
    if (select.value) {
      if (select.value === 'kipo') filter = 'KIPO'
      if (select.value === 'solar') filter = 'SOLAIRE'
      if (select.value === 'aquasmart') filter = 'HUUMBOX'
    }

    fetching.value = true
    const device_values_range = await store.getTotalRange(range.value[0], range.value[1], filter)
    const total = calculate_total_month(device_values_range)
    calculation_results.value = total
    fetching.value = false
  } else {
    calculate()
  }
}

function calculate() {
  if (!range.value || range.value.length == 0) {
    calculation_results.value = store.getTotal(select.value)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.composite-card-data {
  @include vlayout(20px);
  &-header {
    @include hlayout(10px);
    align-items: center;

    &.text-center {
      justify-content: center;
    }

    &-icon {
      width: 36px;
      height: 36px;
      background-color: var(--vc-secondary);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--vc-white);

      img {
        width: 24px;
      }
    }
  }
}
</style>
