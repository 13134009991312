<template>
  <BandeauHome />
  <LayoutContainer class="home-view">
    <div class="home-view-global">
      <div style="width: 100%">
        <h2 style="margin-bottom: var(--spacer)">Mes bâtiments</h2>
        <LayoutColumns :columns="2">
          <CompositeCardUnit
            v-if="store.getRoot"
            v-for="node in store.getRoot.childrens"
            :node="node"
          />
        </LayoutColumns>
      </div>
      <div class="section-savings-interventions">
        <CompositeCardSavings style="margin-bottom: var(--spacer)" />
        <CompositeCardInterventions />
      </div>
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import BandeauHome from '@/components/common/CommonBandeauHome.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeCardUnit from '@/components/composite/CompositeCardUnit.vue'
import LayoutColumns from '@/components/layout/LayoutColumns.vue'
import CompositeCardSavings from '@/components/composite/CompositeCardSavings.vue'
import CompositeCardInterventions from '@/components/composite/CompositeCardInterventions.vue'

const store = useHypervisionStore()
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
select {
  padding: 20px;
}

.home-view {
  @include vlayout(var(--spacer-xl));
  padding-bottom: 80px;

  &-regroupements {
    h2 {
      margin-bottom: var(--spacer-1_5);
    }
  }
}

.home-view-global {
  display: flex;
  width: 100%;
  gap: var(--spacer);
}

.section-savings-interventions {
  min-width: 400px;
}

.tablet,
.mobile {
  .home-view-global {
    flex-direction: column;
  }

  .section-savings-interventions {
    min-width: 0;
    display: grid;
  }
}

.tablet {
  .home-view-global {
    gap: var(--spacer-xl);
  }

  .section-savings-interventions {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacer-xl);
  }
}

.mobile {
  .home-view-global {
    gap: var(--spacer-xl);
  }

  .section-savings-interventions {
    grid-template-columns: 1fr;
    gap: var(--spacer);
  }
}
</style>
